<template>
  <div class="breadcrumbs">
    <RouterLink to="/">Registration</RouterLink>
    <RouterLink v-show="isDevEnv()" to="/bio">Add Details</RouterLink>
    <RouterLink v-show="isDevEnv()" to="/upload-images">Add Works</RouterLink>
    <RouterLink v-show="isDevEnv()" to="/profile-complete">Publish</RouterLink>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  position: relative;
  text-align: center;
  margin: 0 auto;
  color: #bfbfbf;
  a {
    color: #bfbfbf;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 0 20px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      background-image: url(../../assets/images/icon-breadcrumb.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    &:first-child::before {
      display: none;
    }
    &.router-link-exact-active {
      color: #fff;
    }
  }
  .router-link-exact-active ~ a::before {
    filter: grayscale(1);
  }
}
</style>
