<template>
  <footer class="footer">
    <div class="footer-left">
      <img alt="logo" src="@/assets/images/made.png" />
      <span>Made with love from India.</span>
    </div>
    <div class="footer-right">
      <FooterNav />
    </div>
  </footer>
</template>

<script>
import FooterNav from "./FooterNav.vue";

export default {
  name: "FooterSection",
  components: { FooterNav },
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 20px 15px;
  background: rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 768px) {
    background: transparent;
    flex-direction: row;
  }
  .footer-left {
    font-size: 14px;
    font-weight: 300;
    padding: 0 0 16px 0;
    margin: 0 0 16px;
    border-bottom: 1px solid rgba(125, 125, 125, 0.5);
    width: 100%;
    text-align: center;
    img,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      margin-left: 3px;
    }
    @media (min-width: 768px) {
      border: 0;
      padding: 0;
      margin: 0;
      width: 50%;
      text-align: left;
    }
  }
  .footer-right {
    width: 100%;
    text-align: center;
    @media (min-width: 768px) {
      width: 50%;
      text-align: right;
    }
  }
}
</style>
