<template>
  <div class="domain-check-wrap">
    <!-- Sub domain check -->
    <form>
      <div class="input-wrap">
        <div class="input-field">
          <div class="fixed-text">glims.io/</div>
          <input
            id="domain"
            contenteditable="true"
            class="domain"
            placeholder="yourname"
            ref="domainInput"
            @keydown.enter="createPortfolio"
            v-model="searchDomainText"
            autocomplete="off"
          />
        </div>
        <div class="verify-icon">
          <img
            src="@/assets/images/loading.gif"
            v-show="isLoading"
            alt="verify"
          />
          <img
            src="@/assets/images/unavailable-icon.svg"
            v-show="!domainAvailable && !isLoading"
            alt="verify"
          />
          <img
            src="@/assets/images/tick.svg"
            v-show="domainAvailable && !isLoading"
            alt="verify"
          />
        </div>
      </div>
      <button type="button" class="create-btn" v-on:click="createPortfolio()">
        Create Portfolio
      </button>
    </form>
  </div>
</template>
<script>
import axios from "axios";
axios.defaults.withCredentials = true;
import router from "../../router";
import debounce from "debounce";

// Constants
const USERNAME_STATES = {
  UNAVAILABLE: "UNAVAILABLE",
  AVAILABLE: "AVAILABLE",
  // Creation flow
  ALREADY_EXISTS_ONE: "ALREADY_EXISTS_ONE",
  CREATED_NEW: "CREATED_NEW",
};

export default {
  data() {
    return {
      searchDomainText: "",
      domainAvailable: true,
      isLoading: false,
    };
  },
  watch: {
    searchDomainText: function (val, oldVal) {
      if (val.length > 2) {
        this.checkDomainStatus();
      }
    },
  },
  mounted() {
    this.$refs.domainInput.focus(); // Auto-select the input when the page loads
  },
  methods: {
    domainCheckListPass() {
      var pass = true;
      var failureMessage = "";

      // Empty domain name check
      if (this.searchDomainText === "") {
        // TODO: Show toast here.
        pass = false;
        failureMessage = "Username is empty.";
      }

      // TODO: Show toast for "failureMessage"
      if (!pass) {
        this.$toast.warning(failureMessage);
      }

      return pass;
    },
    createPortfolio() {
      if (this.isLoading) {
        return;
      }

      if (!this.domainAvailable) {
        // TODO: Show toast that this domain is taken. And return.
        this.$toast.warning("This username is not available");
        return;
      }

      // Check list for domain
      if (!this.domainCheckListPass()) {
        return;
      }

      // Store domain
      this.$store.state.reserved_domain = this.searchDomainText;

      if (this.isAuthenticated()) {
        this.registerUsername();
      } else {
        let userBioPath = this.$router.resolve({ name: "UserBio" }).fullPath;
        let origin = window.location.origin;
        router.push({
          name: "Register",
          query: {
            redirection_url: `${origin}${userBioPath}`,
          },
        });
      }
    },
    registerUsername() {
      let context = this;
      var url = new URL(`${process.env.VUE_APP_ROOT_API}`);
      url.pathname += "/register_username/";
      url.searchParams.append("username", context.searchDomainText);
      this.showLoader();
      axios
        .get(url)
        .then(function (response) {
          context.hideLoader();
          context.isLoading = false;
          context.genericResponseHandling(response);
        })
        .catch(this.genericErrorHandling);
    },
    checkDomainStatus: debounce(function func() {
      let context = this;

      var url = new URL(`${process.env.VUE_APP_ROOT_API}`);
      url.pathname += "/check_availability/";
      url.searchParams.append("username", context.searchDomainText);

      context.isLoading = true;
      axios.get(url).then(function (response) {
        context.isLoading = false;
        if (!context.genericResponseHandling(response)) {
          return;
        }

        let data = response.data;
        let status = data.status;

        switch (status) {
          case USERNAME_STATES.UNAVAILABLE:
            context.domainAvailable = false;
            break;
          case USERNAME_STATES.AVAILABLE:
            context.domainAvailable = true;
            break;
          default:
            break;
        }
      });
    }, 700),
  },
};
</script>
<style scoped lang="scss">
.domain-check-wrap {
  margin: 20px auto 0;

  @media (min-width: 768px) {
    margin: 30px auto 0;
  }

  @media (min-width: 1024px) {
    margin: 40px auto 0;
  }
}

.domain-check-wrap form {
  margin: 0 auto;
  display: flex;
  width: max-content;
  overflow: hidden;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.input-wrap {
  display: flex;
  align-items: center;

  border: 1px solid #ffffff;
  margin: 0 auto 10px;
  border-radius: 5px;
  max-width: 500px;
  overflow: hidden;

  @media (min-width: 768px) {
    border-right: 0;
    margin: 0 auto;
    border-radius: 5px 0 0 5px;
  }
}

.input-field {
  min-width: 160px;
  display: flex;
  align-items: center;
  padding: 15px 0 15px 25px;
}

.input-wrap .domain {
  background: transparent;
  color: #fff;
  border: 0;
  font-size: 14px;
  outline: 0;
  font-family: inherit;
  white-space: nowrap;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.input-wrap ::placeholder {
  color: #464646;
}

.fixed-text {
  color: #fff;
  cursor: default;
}

.verify-icon {
  width: 16px;
  height: 16px;
  margin-left: 34px;
  margin-right: 16px;
}

.create-btn {
  display: block;
  padding: 17px 20px;
  text-align: center;
  outline: 0;
  margin: 0;
  background: var(--primary-cta-background);
  line-height: 1;
  color: var(--primary-cta-color);
  cursor: pointer;
  border: 1px solid var(--primary-cta-background);
  border-radius: 5px;
  font-size: 14px;
  @media (min-width: 768px) {
    border-radius: 0 5px 5px 0;
    font-size: 16px;
  }
}
</style>
