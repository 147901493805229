import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/upload-images",
    name: "ImageUpload",
    component: () => import("../views/ImageUpload.vue"),
  },
  {
    path: "/bio",
    name: "UserBio",
    component: () => import("../views/Bio.vue"),
  },
  {
    path: "/profile-complete",
    name: "ProfileComplete",
    component: () => import("../views/ProfileReady.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  
  let componentToLoad = "ProfileComplete"

  if (to.name == undefined) { 
    next ({
      name: componentToLoad, 
      query: {
        username: to.path.replaceAll('/','')
      }
    })
  } else {
    next()
  }  
})

// router.afterEach((to, from) => {
//   // Keeps the address bar clean while viewing bio using
//   if (to.name === "ProfileComplete") {
//     window.history.replaceState("", getSubDomain, `/${to.query.username}`);
//   } else if (to.name = "Home") {
//     window.history.replaceState("", getSubDomain, `/`);
//   }
// })

// Utility methods
function hasSubDomain() {
  var urlContainsSubdomain = true
  let siteDomains = ["glims", "localhost", "test"]
  siteDomains.forEach(domain => {
    if (getSubDomain().includes(domain)) {
      urlContainsSubdomain = false
    }
  });
  return urlContainsSubdomain
}

function getSubDomain() { 
  return window.location.hostname.split('.')[0] 
}

function containsURLPath(pathname) { 
  return pathname.replace('/','').length > 0 
}

export default router;
