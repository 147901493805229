<template>
  <header class="header">
    <div class="header-left">
      <img alt="logo" src="@/assets/images/logo.svg" />
    </div>
    <NavBar />
  </header>
</template>

<script>
import NavBar from "./NavBar.vue";
export default {
  name: "HeaderSection",
  components: {
    NavBar,
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 20px 15px;
  justify-content: space-between;
}
</style>
