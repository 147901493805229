<template>
  <div class="welcome-new-user">
    <h1>
      Portfolio for<br />
      busy creatives.
    </h1>
    <h2>The easiest way to show the glimpse of your top works.</h2>
    <CheckDomain />
  </div>
</template>

<script>
import CheckDomain from "./CheckDomain.vue";
export default {
  name: "WelcomeView",
  components: { CheckDomain },
};
</script>

<style scoped lang="scss">
.welcome-new-user {
  text-align: center;
}

h1 {
  font-family: "Satoshi", sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 1;
  margin: 0 0 15px;

  @media (min-width: 768px) {
    font-size: 48px;
    margin: 0 0 20px;
  }

  @media (min-width: 1024px) {
    font-size: 60px;
    margin: 0 0 30px;
  }
}

h2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  margin: 0 0 20px;

  @media (min-width: 768px) {
    font-size: 13px;
  }

  @media (min-width: 1024px) {
    font-size: 15px;
  }
}
</style>
