<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Medium.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Regular.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Black.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Light.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: "Satoshi", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  background: #000;
  color: #fff;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

#app {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.page-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  flex-grow: 1;
}
</style>
