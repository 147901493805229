import { firebase } from '@firebase/app'
import 'firebase/auth'
import 'firebase/analytics'


var firebaseConfig = {
  apiKey: "AIzaSyAbrEwVk8Ok5wyn2HKak_S5QZvRSmfA6u8",
  authDomain: "glims-io.firebaseapp.com",
  projectId: "glims-io",
  storageBucket: "glims-io.appspot.com",
  messagingSenderId: "957328185393",
  appId: "1:957328185393:web:20f24ec00345ed37da1d10",
  measurementId: "G-GT221FSHKD"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

// utils
const auth = firebase.auth()

// export utils/refs
export {
  auth,
  firebase,
  analytics,
}

