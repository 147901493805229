import { createApp } from "vue";
import App from "./App.vue";
import Vuex from 'vuex'
import router from "./router";
import createPersistedState from 'vuex-persistedstate'
import { auth, db, analytics } from './firebase'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import './assets/theme/theme_colors.css'
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

let app = createApp(App)

// Router
app.use(router)
// Vuex
app.use(Vuex)
// Loader
app.use(VueLoading);
// Toast
app.use(ToastPlugin);

const store = new Vuex.Store({
    state: {
      globalUserData: {
        id: "",
        type: "profile",
        username: "",
        display_name: "",
        email: "",
        photoURL: "",
        user_symbol: "🎃",
        user_symbol_bg: "#FFE27B",
      },
      reserved_domain: "",
      isAuthenticated: false,      
    },
    plugins: [createPersistedState()],
    mutations: {
      setUserdisplay_name(state, newData) {
        state.globalUserData.display_name = newData
      },
      setUsername(state, newData) {
        state.globalUserData.username = newData
      },
      globalUserDataUpdate (state, newData) {
        state.globalUserData = newData
      },
      setAuthenticated(state,newData) {
        state.isAuthenticated = newData
      }
    }
  })

// Store
app.use(store)



// Global methods
app.mixin({
    data() {
      return {
        loader: null
      }
    },
    methods: {
      isDevEnv() {
        return process.env.NODE_ENV === 'development'
      },
      isAuthenticated() {
        return store.state.isAuthenticated
      },
      genericResponseHandling(response) {
        var requiresFurtherHandling = true
        let data = response.data

        // Hide loader if already shown
        this.hideLoader();

        // Note: link to repo https://www.npmjs.com/package/vue-toast-notification
        // Show toast if required
        if(data.toast) {
          switch (data.toast.type) {
            case "SUCCESS":
                this.$toast.success(data.toast.message);
              break;
            case "ERROR":
              this.$toast.error(data.toast.message);
              break;
            case "WARNING":
              this.$toast.warning(data.toast.message);
              break;
            case "INFO":
              this.$toast.info(data.toast.message);
              break;
            default:
              this.$toast.default(data.toast.message);
              break;
          }
          
        }

        // Redirect to another screen
        if(data.redirect) {
          router.push(data.redirect);
          requiresFurtherHandling = false
        }

        return requiresFurtherHandling
      },
      genericErrorHandling(error) {
        // Hide loader if already shown
        this.hideLoader()

        // show toast if required
        let message = "Something went wrong :("
        this.$toast.error(message);
      },
      convertToArray(arrayData) {
        var array = [];
        for (var k in arrayData) {
          array.push(arrayData[k]);
        }
        return array;
      }, 
      isValidURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      },
      showLoader() {
        this.loader = this.$loading.show({
          color: "#F0FF64",
          backgroundColor: "#000",
          loader: "bars"
        });
      },
      hideLoader() {
        if (this.loader) {
          this.loader.hide();
        }        
      },
      showToast(msg, position = 'top') {
        // TODO:
        // app.$toast.open({
        //   message: msg,
        //   type: "success",
        //   position: position,
        //   duration: 3000,
        //   dismissible: true,
        //   queue: true,
        // });
      },
      showWarningToast(msg, position = 'top') {
        // TODO:
        // app.$toast.open({
        //   message: msg,
        //   type: "warning",
        //   position: position,
        //   duration: 1300,
        //   dismissible: true,
        //   queue: true,
        // });
      },
      logEvent(eventName,eventData) {
        var eventUserData = eventData
        eventUserData["username"] = store.state.globalUserData.username
        eventUserData["userId"] = store.state.globalUserData.id
        analytics.logEvent(eventName,eventUserData)
      },
    }
  })


  // Mount
  app.mount("#app");