<template>
  <ul>
    <li>
      <RouterLink to="/">Portfolio</RouterLink>
    </li>
    <li>
      <RouterLink to="/">Blog</RouterLink>
    </li>
    <li>
      <RouterLink to="/">Terms</RouterLink>
    </li>
    <li>
      <RouterLink to="/">Contact</RouterLink>
    </li>
  </ul>
</template>

<script>
export default {
  name: "FooterNav",
};
</script>

<style scoped lang="scss">
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    color: #fff;
    font-size: 14px;
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #ddd;
      }
    }
  }
}
</style>
