<template>
  <BreadCrumbs v-if="isAuthenticated()" />
  <nav class="header-right" :class="isOpen ? 'showMenu' : ''">
    <ul v-if="!isAuthenticated()">
      <li>
        <RouterLink to="/">Home</RouterLink>
      </li>
      <li>
        <RouterLink to="/">How it works</RouterLink>
      </li>
      <li>
        <RouterLink to="/">Why Glims</RouterLink>
      </li>
      <li>
        <RouterLink to="/">About</RouterLink>
      </li>
      <li v-show="isDevEnv()">
        <RouterLink to="/bio">Bio</RouterLink>
      </li>
      <li v-show="isDevEnv()">
        <RouterLink to="/upload-images">Banners</RouterLink>
      </li>
      <li v-show="isDevEnv()">
        <RouterLink to="/profile-complete">Final Page</RouterLink>
      </li>
    </ul>

    <div class="link_button">
      <RouterLink v-if="!isAuthenticated()" to="/register">Login / Register</RouterLink>
      <button type="button" v-if="isAuthenticated()" v-on:click="logout()">
        Logout
      </button>
    </div>
    <div class="menu-toggler" @click="openMenu">
      <img src="@/assets/images/menu.svg" alt="menu" />
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import BreadCrumbs from "./BreadCrumbs.vue";
export default {
  name: "NavBar",
  components: {
    BreadCrumbs,
  },
  methods: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    logout() {
      var url = `${process.env.VUE_APP_ROOT_API}/logout_user/`;

      axios({
        method: "get",
        url: url,
      }).then(function (response) { });
      this.$store.commit("setAuthenticated", false);
      document.location.href = "/";
    },
  },
};
</script>

<script setup>
import { ref } from "vue";
const isOpen = ref(false);
const openMenu = () => {
  isOpen.value = !isOpen.value;

  console.log(isOpen);
};
</script>

<style scoped lang="scss">
.header-right {
  display: flex;
  align-items: center;

  &.showMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 30px 15px;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.9);
    overflow: auto;
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
  }
}

.header-right.showMenu {
  ul {
    display: block;
  }

  .link_button {
    margin-left: 0;
  }
}

.header-right ul li {
  display: block;
  margin: 15px 0;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #ddd;
    }
  }
}

.menu-toggler {
  cursor: pointer;
  margin-left: 20px;
}

.link_button {
  margin-left: 25px;

  a,
  button {
    display: block;
    background: #f0ff64;
    color: #000;
    border-radius: 3px;
    padding: 8px 16px;
    text-decoration: none;
    margin: 0;
    border: 0;
    outline: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;

    img {
      margin-left: 5px;
    }

    &:hover {
      background: #ff0;
    }
  }
}

.header-right.showMenu .menu-toggler {
  position: absolute;
  right: 15px;
  top: 25px;
}

@media (min-width: 768px) {
  .menu-toggler {
    display: none;
  }

  .header-right.showMenu {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    padding: 0;
    z-index: 99999;
    background: transparent;
    display: flex;

    .link_button {
      margin-left: 25px;
    }
  }

  .header-right ul {
    display: block;

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 2vw;
    }
  }
}
</style>
