<template>
  <HeaderSection />
  <main class="page-content">
    <WelcomeView />
  </main>
  <FooterSection />
</template>

<script>
import HeaderSection from "../components/Header/HeaderSection.vue";
import FooterSection from "../components/Footer/FooterSection.vue";
import WelcomeView from "../components/NewUser/WelcomeView.vue";
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "Home",
  components: {
    WelcomeView,
    HeaderSection,
    FooterSection,
  },
  mounted() {
    this.showLoader()
    let context = this;
    var url = new URL(`${process.env.VUE_APP_ROOT_API}`);
    url.pathname += "/get_deeplink/";
    url.searchParams.append("screen", "HOME");
    axios
      .get(url)
      .then(context.genericResponseHandling)
      .catch(this.genericErrorHandling);

  }
};
</script>
